export class BuyClick {

  constructor() {
    this.buyClickModal();
    // this.buyClickForm();
  }

  buyClickModal() {
    let buyClickModal = $('.buyСlick');
    buyClickModal.hide();
    buyClickModal.iziModal();

    $(document).on('click', '.button__toCart button', () => {
      buyClickModal.iziModal('open');
    });
  }

  buyClickForm() {
    let $this = this;

    $(document).on('submit', '#callback', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let callbackData = $this.parseResult(serializeData);
      let isValid = $this.validation(callbackData);
      if (callbackData && isValid) {
        let data = {
          action: 'ajaxCallback',
          data: callbackData,
        };

        $this.ajax(data).then(result => {
          console.log('success');
          return result
        });
      }
    });
  }

  ajax(data) {
    return $.post(window.ajaxurl, data);
  }

  validation(data) {
    let errors = [];
    let index = -1;
    let inputTel = $('input[type="tel"]');
    let errorText = [];

    if (data.phone.length === 13) {

      index = errors.indexOf('phone');
      inputTel.removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }

    } else {
      errors.push('phone')
      inputTel.addClass('error')
      errorText.push('<div>Не верно введен номер телефона</div>');
    }

    if (data.name) {
      index = errors.indexOf('name');
      $('input[type="text"]').removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }

    } else {
      errors.push('name')
      $('input[type="text"]').addClass('error')
      errorText.push('<div>Поле "ФИО" не может быть пустым</div>');
    }

    $('.error').html(errorText);
    return !errors.length;
  }


  parseResult($fields) {
    let result = {};

    $.each($fields, function () {
      result[this.name] = this.value;
    });

    return result;
  }
}

$(document).ready(function () {
  new BuyClick();
});
