export class UserActions {

  constructor() {
    this.Registration();
    this.Login();
    this.Logout();
    this.ForgotPassword();
    this.ContactsForm();
    this.CallbackForm();
  }

  static validateEmail(value) {
    let re = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
    return re.test(value);
  }

  static validatePhone(value) {
    let re = /^[0-9-()+]{10,17}$/;
    return re.test(value);
  }

  static ajaxAction($url, $target, $data, $success) {
    $.ajax({
      url: $url,
      type: 'post',
      data: $data,
      dataType: 'json',
      success: $success
    });
  }

  validate($form) {
    let errors = [];
    $form.find('input').each((index, item) => {
      console.log($(item).attr('type'));
      switch ($(item).attr('type')) {
        case 'email' :
          if (!this.constructor.validateEmail($(item).val())) {
            $(item).parent().find('.text-danger').show();
            $(item).addClass('error');
            errors.push($(item));

          } else {
            $(item).removeClass('error');
            $(item).parent().find('.text-danger').hide();
          }
          break;
        case 'tel' :
        case 'phone' :
          if (!this.constructor.validatePhone($(item).val())) {
            console.log($(item));
            $(item).parent().find('.text-danger').show();
            $(item).addClass('error');
            errors.push($(item));

          } else {
            $(item).removeClass('error');
            $(item).parent().find('.text-danger').hide();
          }
          break;

        case 'password' :
          if ($(item).val().length < 10) {
            $(item).addClass('error');
            errors.push($(item));
          } else {
            $(item).removeClass('error');
          }
          break;

        default:
          if ($(item).val().length < 3) {
            $(item).parent().find('.text-danger').show();
            $(item).addClass('error');
            errors.push($(item));
          } else {
            $(item).removeClass('error');
            $(item).parent().find('.text-danger').hide();
          }
          break;
      }
    });

    return !errors.length;
  }

  Registration() {
    $(document).on('submit', '.form--register', (e) => {
      e.preventDefault();
      let $form = $(e.target);

      let user_data = {
        firstname: $form.find('input[name=name]').val(),
        lastname: $form.find('input[name=name]').val(),
        email: $form.find('input[name=email]').val(),
        telephone: $form.find('input[name=phone]').val(),
        password: $form.find('input[name=password]').val(),
      };
      if (this.validate($form)) {
        let $success = function (e) {
          if (e.redirect) {
            location.href = e.redirect;
          }
        };
        this.constructor.ajaxAction('index.php?route=account/register/ajaxRegistration', $form, user_data, $success);
      }
    });
  }

  Login() {
    $(document).on('submit', '.form--login', (e) => {
      e.preventDefault();

      let $form = $(e.target);
      let user_data = {
        email: $form.find('input[name=email]').val(),
        password: $form.find('input[name=password]').val()
      };
      if (this.validate($form)) {
        if (this.validate($form)) {
          let $success = function (e) {
            if (e.redirect) {
              location.href = e.redirect;
            } else if (e.user_check && !e.redirect) {
              alert('Не правильный пароль')
            } else if (!e.user_check) {
              alert('Такого пользователя не существует')
            }
          };
          this.constructor.ajaxAction('index.php?route=account/login/ajaxLogin', $form, user_data, $success);
        }
      }
    });
  }

  Logout() {
    $(document).on('click', '.logout_link', (e) => {
      e.preventDefault();
      $.post('index.php?route=account/logout/ajaxLogout', function (e) {
        location.href = e.redirect;
      })
    })
  }

  ForgotPassword() {
    $(document).on('submit', '.form--reset', (e) => {
      e.preventDefault();
      let $form = $(e.target);

      let user_data = {
        email: $form.find('input[type=email]').val(),
      };
      console.log(user_data);
      if (this.validate($form)) {
          let $success = function (e) {
            if (e.success) {
              location.href = e.redirect;
            } else {
              $($form).addClass('error');
            }
          };
          this.constructor.ajaxAction('index.php?route=account/forgotten/ajaxRestorePassword', $form, user_data, $success);
      }
    });
  }

  ContactsForm() {
    // btn-main--disabled
    $('.feedback').find('.text-danger').hide();
    $(document).on('submit', '.fb-form', (e) => {
      e.preventDefault();

      let $form = $(e.target);
      let user_data = {
        email: $form.find('input[name=email]').val(),
        name: $form.find('input[name=name]').val(),
        phone: $form.find('input[name=phone]').val(),
        enquiry: $form.find('#input-enquiry').val(),
      };
      if (this.validate($form)) {
        let $success = function (e) {
          let $modal = $('.modal--success');
          if (e.success) {
            $modal.iziModal('open');
            $modal.find('.modal__title').text('Сообщение отправлено');
            $('.fb-form').each(function () {
              $(this).find('input,textarea').val('');
            });
            setTimeout(() => {
              $modal.iziModal('close');
            }, 1500);
          }
        };
        this.constructor.ajaxAction('index.php?route=information/contact/AjaxForm', $form, user_data, $success);
      }
    })
  }

  CallbackForm() {
    $(document).on('submit', '.form--callback', (e) => {
      e.preventDefault();

      let $form = $(e.target);
      let user_data = {
        name: $form.find('input[name=name]').val(),
        phone: $form.find('input[name=phone]').val(),
      };
      if (this.validate($form)) {
        let $success = function (e) {
          let $modal = $('.modal--success');
          if (e.success) {
            $modal.iziModal('open');
            $modal.find('.modal__title').text('Сообщение отправлено');
            $('.fb-form').each(function () {
              $(this).find('input,textarea').val('');
            });
            setTimeout(() => {
              $modal.iziModal('close');
            }, 1500);
          }
        };
        this.constructor.ajaxAction('index.php?route=information/contact/AjaxForm', $form, user_data, $success);
      }
    })
  }

}


