$(document).ready(function () {

  $(document).on('scroll', function() {
    let header = $('.header');
    let scrollTop = $(document).scrollTop();
    if (scrollTop >= 1) {
      header.addClass('header--scrolled');
    } else {
      header.removeClass('header--scrolled');
    }
  });

  if (location.hash) {
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 1);
  }
  $(document).on('click', '[data-scroll]', function() {
    let id = $(this).data('scroll'),
      position = $(window).height();
    if(id.length) {
      console.log(id);
      position = $(id).offset().top
    }
    $('body, html').stop().animate({
      scrollTop: position
    }, 1500);
  });

  $('.chat-circle span').on('click', function() {
    $(this.parentNode).toggleClass('active');
  });

  $('.category__description span.show-more').on('click', function() {
    $(this.parentNode).parent().toggleClass('active');
  });
});
/* eslint-disable */
export function AnimatePlaceholder(selector) {
  if ($(selector).find('input, textarea').val()) {
    $(selector).find('.placeholder').addClass('active');
  }

  $(selector).find('.placeholder').click(function() {
    $(selector).find('input, textarea').focus();
  });

  $(selector).find('input, textarea').focus(function() {
    if (!$(this).val()) {
      $(selector).find('.placeholder').addClass('active');
    }
  });

  $(selector).find('input, textarea').blur(function() {
    if (!$(this).val()) {
      $(selector).find('.placeholder').removeClass('active');
    }
  })
}
