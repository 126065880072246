export class HomePage {

  constructor() {
    this.popularProductsSlider();
    this.headerMobile();
  }

  popularProductsSlider() {
    $(document).ready(function () {
      let owlCarousel = $(".owl-carousel")
      owlCarousel.owlCarousel({
        items: 5,
        autoplay: true,
        autoplayTimeout: 3000,
        loop: true,
        dots: false,
        responsive : {
          300 : {
            items: 2,
          },
          768 : {
            items: 5,
          }
        }
      });

      $('.next_button').click(function() {
        owlCarousel.trigger('prev.owl.carousel');
      })

      $('.prev_button').click(function() {
        owlCarousel.trigger('next.owl.carousel');
      })
    });
  }

  headerMobile() {
    $(document).on('click', '.mobileButton', function () {
      $(this).parent().find(".mobileContent").slideToggle();
    })
  }
}

$(document).ready(function () {
  new HomePage();
});
