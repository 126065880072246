// $(document).ready(function () {
//   let slider = $('.slideshow__slides');
//
//   if(slider.length) {
//   slider.slick({
//     lazyLoad: 'ondemand',
//     rows: 0,
//     slide: '.slideshow__slide',
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     infinite: true,
//     autoplaySpeed: 4000,
//     speed: 800,
//     fade: true,
//     arrows: false,
//     dots: false,
//     swipe: false,
//     touchMove: false,
//     draggable: false,
//   });
//
//   let total = slider.slick('getSlick').$slides.length;
//   total = total < 10 ? '0' + total : total;
//   $('.slideshow__counter-total').text(total);
//
//   slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
//     sliderCounter($('.slideshow__counter-current'), nextSlide + 1);
//   });
//
//   }
//
// });
//
//
// function sliderCounter(el, current = 1) {
//   let slide = current < 10 ? '0' + current : current;
//   el.text(slide);
// }
//
