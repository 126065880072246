let header_events = {
  init() {
    this.openMobileMenu();
    this.closeMobileMenu();
  },
  openMobileMenu() {
    $('.hamburger').click(function () {
      $(this).addClass('hamburger--active');
      $('.header__close').css('z-index', '3');
      $('.header').addClass('header--menu-active');
      setTimeout(function () {
        $('.header').addClass('header--menu-visible');
      }, 100);
    });
  },

  closeMobileMenu() {
    $('.header__close').click( function () {
      $(this).css('z-index', '-1');
      $('.hamburger').removeClass('hamburger--active');
      $('.header').removeClass('header--menu-visible');
      setTimeout(function () {
        $('.header').addClass('header--menu-active');
      }, 600);
    })
  }
};

$(document).ready(function () {
  header_events.init();
});
