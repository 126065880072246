/* eslint-disable */
export class ModalActions {
  constructor() {
    this.callBackModal();
    this.callBackForm();
    this.buyClickModal();
    this.buyClickForm();
    this.sendQuestionModal();
    this.sendQuestionForm();
  }

  sendQuestionModal() {
    let buyClickModal = $('.sendQuestion');
    buyClickModal.hide();
    buyClickModal.iziModal();

    $(document).on('click', '.button__question button', () => {
      buyClickModal.iziModal('open');
    });
  }


  sendQuestionForm() {
    let $this = this;

    $(document).on('submit', '#sendQuestion', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let sendQuestionData = $this.parseResult(serializeData);
      let isValid = $this.validation(sendQuestionData);

      if (sendQuestionData && isValid) {
        sendQuestionData.location = window.location.href;
        let $success = function(res) {
          if (res.success) {
            $('#sendQuestion').hide();
            $('.sendQuestion__success').show();
          }
        };

        $this.ajaxAction('index.php?route=information/contact/AjaxForm', sendQuestionData, $success);
      }
    });
  }

  callBackModal() {
    let callBackModal = $('.callbackModal');
    callBackModal.hide();
    callBackModal.iziModal();

    $(document).on('click', 'button.callback', () => {
      callBackModal.iziModal('open');
    });
  }

  callBackForm() {
    let $this = this;

    $(document).on('submit', '#callback', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let callbackData = $this.parseResult(serializeData);
      let isValid = $this.validation(callbackData);
      if (callbackData && isValid) {
        let $success = function (res){
          if (res.success) {
            alert('Ваше сообщение отправлено')
            $('.callbackModal').iziModal('close');
          }
        };

        $this.ajaxAction('index.php?route=information/contact/AjaxForm', callbackData, $success);
      }
    });
  }

  buyClickModal() {
    let buyClickModal = $('.buyClick');
    $('.button .result').hide();
    buyClickModal.hide();
    buyClickModal.iziModal();

    $(document).on('click', '.button__toCart button', () => {
      buyClickModal.iziModal('open');
    });
  }

  buyClickForm() {
    let $this = this;

    $(document).on('submit', '#buyClick', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let data = $this.parseResult(serializeData);
      let isValid = $this.validation(data);
      if (data && isValid) {
        data.products = [data.product_id];

        let $success = function(res) {
          console.log(res);
        };

        setTimeout(() => {
            alert('Ваше сообщение отправлено')
            $('.buyClick').iziModal('close');
        }, 1000)

        $this.ajaxAction('index.php?route=checkout/confirm/ajaxCheckout', data, $success);
      }
    });
  }

  validation(data) {
    let errors = [];
    let index = -1;
    let inputTel = $('input[type="tel"]');
    let errorText = [];

    if ( /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(data.phone)) {
      index = errors.indexOf('phone');
      inputTel.removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }
    } else {
      errors.push('phone')
      inputTel.addClass('error')
      errorText.push('<div>Не верно введен номер телефона</div>');
    }

    if (Object.keys(data).includes('email')) {
      if (this.validEmail(data.email)) {
        index = errors.indexOf('email');
        $('input[type="email"]').removeClass('error')

        if (index >= 0) {
          errors.splice(index, 1);
        }
      } else {
          errors.push('email')
          $('input[type="email"]').addClass('error')
        }
    }

    if (data.name) {
      index = errors.indexOf('name');
      $('input[type="text"]').removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }
    } else {
      errors.push('name')
      $('input[type="text"]').addClass('error')
      errorText.push('<div>Поле "ФИО" не может быть пустым</div>');
    }

    $('.error').html(errorText);
    return !errors.length;
  }

  validEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }


  parseResult($fields) {
    let result = {};

    $.each($fields, function () {
      result[this.name] = this.value;
    });

    return result;
  }

  ajaxAction($url, $data, $success) {
    $.ajax({
      url: $url,
      type: 'post',
      data: $data,
      dataType: 'json',
      success: $success
    });
  }
}

$(document).ready(function () {
  new ModalActions();
});
