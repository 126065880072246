$(document).ready(function () {
  $('.modal').iziModal({
    width: 540,
    group: 'grupo1',
    restoreDefaultContent: true,
    fullscreen: false,
    radius: 15,
    arrowKeys: false,
    navigateArrows: false,
    navigateCaption: false,
  });

  $(document).on('click', '[data-modal]', function (event) {
    event.preventDefault();
    let id = $(this).data('modal');
    $('.modal--' + id).iziModal('open');
  });
});
