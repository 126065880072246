export class SingleProduct {

  constructor() {
    this.productSlider();
  }

  productSlider() {
    $('.slider-for .thumbnails').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
      centerPadding: '60px',
      fade: true,
      asNavFor: '.slider-nav .thumbnails',
      cssEase: 'linear',
      speed: 500,
    });
    $('.slider-nav .thumbnails').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-for .thumbnails',
      dots: false,
      prevArrow: $('.slider-nav .prev'),
      nextArrow: $('.slider-nav .next'),
      arrows: true,
      centerMode: false,
      focusOnSelect: true,
      centerPadding: 0,
    });
  }
}

$(document).ready(function () {
  new SingleProduct();
});
