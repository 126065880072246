import 'jquery';
import 'izimodal'
import 'simplelightbox'
import 'slick-carousel'
import 'owl.carousel/dist/owl.carousel.min'
import './components/BuyClick.js'; import './components/home/hero.js'; import './components/HomePage.js'; import './components/ModalActions.js'; import './components/SingleProduct.js';
import "./layouts/header.js"; import "./layouts/lightbox.js"; import "./layouts/modals.js";
import './app';
import {UserActions} from "./classes/UserActions";

$(document).ready(function () {
  new UserActions();
});
